var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _vm._v(" "),
      _vm.platform && _vm.platform.platformAiChatOpen == 1
        ? _c(
            "div",
            {
              staticClass: "ai-btn",
              attrs: { id: "dragImg" },
              on: { mousedown: _vm.mousedown }
            },
            [
              _c("img", {
                attrs: { src: _vm.$IMG_DOMAIN + "/ai.gif", alt: "" },
                on: { click: _vm.handleClickOpenAi }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }