import request from '@/utils/request';
export function getAllData(appid) {
  return request({
    url: "/p2/api/v2/wx/weapp/all-data?appid=".concat(appid),
    method: 'get'
  });
} //

export function saveAllData(firstClassId, data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/all-data",
    method: 'post',
    data: {
      firstClassId: firstClassId,
      classList: data
    }
  });
} //搜索小程序下的文章列表

export function getAllArticle(page, data) {
  return request({
    url: "/p2/api/v1/weapp/manager/article/list?page=".concat(page),
    method: 'post',
    data: data
  });
} //搜索小程序下的群列表

export function getAllProject(page, data) {
  return request({
    url: "/p2/api/v1/weapp/manager/project/list?page=".concat(page),
    method: 'post',
    data: data
  });
} //搜索小程序下的活动列表

export function getAllActivity(page, data) {
  return request({
    url: "/p2/api/v1/weapp/manager/activity/list?page=".concat(page),
    method: 'post',
    data: data
  });
} //搜索小程序下的圈子列表

export function getAllCircle(page, data) {
  return request({
    url: "/p2/api/v1/weapp/manager/circle/list?page=".concat(page),
    method: 'post',
    data: data
  });
} //搜索我的圈子列表

export function getMyCircle(pageNum, keyword, searchType) {
  var obj = {
    page: pageNum,
    searchType: searchType
  };

  if (keyword && keyword != "") {
    obj.keywords = keyword;
  }

  return request({
    url: "/p2/api/v1/weapp/manager/circle/list?page=".concat(pageNum),
    method: 'post',
    data: obj
  });
} //搜索我的圈子列表

export function getMyCircleO(page, data) {
  return request({
    url: "/p2/api/v1/my-circle/search?page=".concat(page),
    method: 'post',
    data: data
  });
} //搜索我的商品列表

export function getMyProduct(page, data) {
  var size = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;
  return request({
    url: "/p1/api/v1/weapp/manager/mall/on-sale-goods/search?page=".concat(page, "&size=").concat(size),
    method: 'post',
    data: data
  });
} //搜索公众号的文章

export function getAllMpArticle(page, data) {
  return request({
    url: "/p2/api/v1/wx/third-party/weapp/mp-article/list?page=".concat(page),
    method: 'post',
    data: data
  });
} //创建一个非正式的小程序

export function createNameMini(data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/temp",
    method: 'post',
    data: data
  });
} //查询公众号列表

export function getBindMp(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/mp-list?appid=".concat(appid),
    method: 'get'
  });
} //查询公众号文章列表（小程序设计搜索页）

export function getMpArticles(id, data, page) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/mp/".concat(id, "/search-articles?page=").concat(page, "&size=", 50),
    method: 'post',
    data: data
  });
} //获取小程序授权码的地址

export function getAuthUrl(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/get-auth-url?appid=".concat(appid),
    method: 'get'
  });
} //生成预览的小程序码

export function getQrcodeUrl(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/preview?appid=".concat(appid),
    method: 'get'
  });
} //生成预览的小程序码

export function getMiniStatu(appid) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/status?appid=".concat(appid),
    method: 'get'
  });
} //查询成员列表

export function getUser(page, data) {
  return request({
    url: "/p2/api/v1/mobile/weapp/user/search?page=".concat(page),
    method: 'post',
    data: data
  });
}
/**
 * 查询成员详情
 * @param {String} id 成员id
 * @returns 
 */

export function getUserSingle(id) {
  return request({
    url: "/p1/api/v1/weapp/user/".concat(id, "/detail"),
    method: 'get'
  });
}
/**
 * 修改成员的内容数量
 * @param {Object} data 
 * @returns 
 */

export function putUserSingle(data) {
  return request({
    url: "/p1/api/v1/weapp/user/content-limit",
    method: 'put',
    data: data
  });
} //查询成员申请的待办列表（旧版）

export function getUser2(page, data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/user-apply/list?page=".concat(page),
    method: 'post',
    data: data
  });
} //查询成员申请的待办列表(2021-7-9新增)

export function getTodoList(page, data) {
  return request({
    url: "/p2/api/v1/wx/third-party/weapp/unaudit-todo/list?page=".concat(page),
    method: 'get'
  });
} //查询管理助手下的已处理的云协作待办列表

export function getTodoProjectList(page) {
  return request({
    url: "/p2/api/v1/wx/third-party/weapp/audited-project-todo/list?page=".concat(page),
    method: 'get'
  });
} //查询管理助手下的已处理的圈子待办列表

export function getTodoCircleList(page) {
  return request({
    url: "/p2/api/v1/wx/third-party/weapp/audited-circle-member-apply/list?page=".concat(page),
    method: 'get'
  });
} //查询管理助手下的已处理的商品待办列表

export function getTodoGoodsList(page) {
  return request({
    url: "/p1/api/v1/weapp/manager/publish-info/processed-todo-list?page=".concat(page),
    method: 'get'
  });
} //查询管理助手下的已处理的授权代办列表

export function getTodoApplyList(page) {
  return request({
    url: "/p2/api/v1/wx/third-party/weapp/audited-co-auth-apply/list?page=".concat(page),
    method: 'get'
  });
} //查询我管理的全部小程序下的成员申请的待办列表

export function getTodoUserList(page) {
  return request({
    url: "/p2/api/v1/wx/third-party/wx/third-party/weapp/user-apply/list?page=".concat(page),
    method: 'get'
  });
} //查询我管理的全部小程序下的志愿活动的待办列表

export function getTodoTissueList(page) {
  return request({
    url: "/p2/api/v1/wx/third-party/weapp/audited-vol-activity-member-apply/list?page=".concat(page),
    method: 'get'
  });
} //删除成员

export function deleteUser(userId) {
  return request({
    url: "/p2/api/v1/weapp/manager/delete-user?userId=".concat(userId),
    method: 'delete'
  });
} //审核成员申请的待办

export function applyUser(id, auditStatus, canWrite) {
  return request({
    url: "/p2/api/v1/weapp/manager/user-apply/audit",
    method: 'put',
    data: {
      id: id,
      auditStatus: auditStatus,
      canWrite: canWrite
    }
  });
} //小程序更换管理者发送短信验证码

export function getBossCode(data) {
  return request({
    url: "/p2/api/v1/sms/captcha/weapp-change-manager",
    method: 'post',
    data: data
  });
} //小程序更换管理者发送短信验证码，验证原管理者的短信验证码

export function validateBossPhone(phone, captcha) {
  return request({
    url: "/p2/api/v1/sms/captcha/weapp-change-manager/validate?phone=".concat(phone, "&captcha=").concat(captcha),
    method: 'get'
  });
} //小程序更换管理者发送短信验证码，验证原管理者的短信验证码

export function changeManager(data) {
  return request({
    url: "/p2/api/v1/weapp/manager/user/change-manager",
    method: 'put',
    data: data
  });
} //搜索小程序列表，根据名称搜索

export function searchMini(page, data) {
  return request({
    url: "/p2/api/v1/mobile/weapp/search?page=".concat(page),
    method: 'post',
    data: data
  });
} //申请成为小程序成员

export function userApply(data, coidAppid) {
  return request({
    url: "/p2/api/v1/weapp/user-apply",
    method: 'post',
    data: data,
    coidAppid: coidAppid
  });
} //查询当前用户的申请小程序成员的记录列表

export function searchHistory(page) {
  return request({
    url: "/p2/api/v1/mobile/weapp/user-apply/history/list?page=".concat(page),
    method: 'get'
  });
}
/**
 * 读取公开内容文章列表
 * @param {Number} page 页码
 * @param {Object} data 传给接口的值
 */

export function loadGkArticleList(page, data) {
  return request({
    url: "/p2/api/v1/public-lib/articles/search?page=".concat(page),
    method: 'post',
    data: data
  });
}
/**
 * 读取公开内容活动列表
 * @param {Number} page 页码
 * @param {Object} data 传给接口的值
 */

export function loadGkActivityList(page, data) {
  return request({
    url: "/p2/api/v1/public-lib/actiInfos/search?page=".concat(page),
    method: 'post',
    data: data
  });
}
/**
 * 读取公开内容圈子列表
 * @param {Number} page 页码
 * @param {Object} data 传给接口的值
 */

export function loadGkCircleList(page, data) {
  return request({
    url: "/p2/api/v1/public-lib/circles/search?page=".concat(page),
    method: 'post',
    data: data
  });
}
/**
 * 审批云协作
 * @param {String} id
 * @param {Number} auditStatus
 * @param {String} todoId
 */

export function agreeProject(id, auditStatus, todoId) {
  var data = {
    auditResult: auditStatus,
    id: id,
    todoId: todoId
  };
  return request({
    url: "/p2/api/v1/todoaudits",
    method: 'put',
    data: data
  });
}
/**
 * 审批活动
 * @param {String} id
 * @param {Number} auditStatus
 * @param {String} todoId
 */

export function agreeActivity(id, auditStatus, todoId) {
  var data = {
    auditResult: auditStatus,
    id: id,
    todoId: todoId
  };
  return request({
    url: "/p2/api/v1/invite-raters/audit",
    method: 'put',
    data: data
  });
}
/**
 * 审批文章
 * @param {String} id
 * @param {Number} auditStatus
 * @param {String} todoId
 */

export function agreeArticle(id, auditStatus, todoId) {
  var data = {
    auditStatus: auditStatus,
    id: id,
    todoId: todoId
  };
  return request({
    url: "/p2/api/v1/todo/auth-audit",
    method: 'put',
    data: data
  });
}
export function agreeCircle(id, auditStatus) {
  var data = {
    auditStatus: auditStatus,
    id: id
  };
  return request({
    url: "/p2/api/v1/circle/member-apply/audit",
    method: 'put',
    data: data
  });
}
export function agreeTissue(id, auditStatus) {
  var data = {
    auditStatus: auditStatus,
    id: id
  };
  return request({
    url: "/p2/api/v1/vol-activity/member-apply/audit",
    method: 'put',
    data: data
  });
}
export function agreeGoods(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/publish-info/content-audit",
    method: 'PUT',
    data: data
  });
}
/******
 * 获取学校管理者列表
 */

export function getManagementList(page, data) {
  return request({
    url: "/p1/api/v1/weapp/manager/class/weapp/user/search?page=".concat(page),
    method: "POST",
    data: data
  });
} // 查询专栏列表（不含首页）

export var getSpecialList = function getSpecialList() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/first-class/list?searchType=".concat(type),
    method: "GET"
  });
};
/****
 * 设置学校管理者
 */

export function getClassManagement(type, id) {
  return request({
    url: "/p1/api/v1/weapp/manager/class/weapp/user/set-school-mgr",
    method: "PUT",
    data: {
      id: id,
      isSchoolMgr: type ? 0 : 1
    }
  });
}
/**
 * 创建圈子
 */

export function createCircle(obj) {
  return request({
    url: "/p2/api/v1/circle",
    method: "POST",
    data: obj
  });
}
/**
 * 编辑圈子
 */

export function editCircle(obj) {
  return request({
    url: "/p2/api/v1/circle",
    method: "PUT",
    data: obj
  });
}
/**
 * 查询圈子详情
 */

export function getCircle(id) {
  return request({
    url: "/p2/api/v1/circle/".concat(id, "/detail"),
    method: "GET"
  });
}
/**
 * 小程序邀请成员
 * @param {Object} data
 */

export function inviteMember(data) {
  return request({
    url: "/p1/api/v1/weapp/invite-member",
    method: "POST",
    data: data
  });
}
/**
 * 根据小程序邀请成员的邀请码，查询邀请详情
 * @param {String} inviteCode 邀请码
 */

export function getInviteInfo(inviteCode) {
  return request({
    url: "/p1/api/v1/weapp/invite-member/".concat(inviteCode, "/detail"),
    method: "GET"
  });
}
/**
 * 接受邀请加入
 * @param {Object} data
 */

export function applyInvite(data) {
  return request({
    url: "/p1/api/v1/weapp/invite-member/agree",
    method: "POST",
    data: data
  });
} // 保存设计内容判断是否有私密数据

export function isSecretFn(appid, data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/all-data/secreted-article-count?appid=".concat(appid),
    method: "POST",
    data: data
  });
}
/**
 * 搜索文集列表
 * @param {Object} page
 * @param {Object} data
 */

export function getColumnList(page, data) {
  return request({
    url: "/p1/api/v1/weapp/manager/column-info/on-sale/search?page=".concat(page),
    method: 'post',
    data: data
  });
} // 文字

export function saveTextFn(data) {
  return request({
    url: '/p1/api/v1/weapp/data-slice/text',
    method: 'post',
    data: data
  });
}
export function editTextFn(data) {
  return request({
    url: '/p1/api/v1/weapp/data-slice/text',
    method: 'PUT',
    data: data
  });
}
export function saveTextFn2(data) {
  return request({
    url: '/p1/api/v2/weapp/data-slice/text',
    method: 'post',
    data: data
  });
}
export function editTextFn2(data) {
  return request({
    url: '/p1/api/v2/weapp/data-slice/text',
    method: 'PUT',
    data: data
  });
} // 图片

export function saveImgFn(data) {
  return request({
    url: '/p1/api/v1/weapp/data-slice/image',
    method: 'post',
    data: data
  });
}
export function editImgFn(data) {
  return request({
    url: '/p1/api/v1/weapp/data-slice/image',
    method: 'PUT',
    data: data
  });
}
/**
 * 获取模板库列表
 */

export function getTemplateList() {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/template-standard/list",
    method: 'get'
  });
}
/**
 * 使用小程序模板
 * @param {Object} data
 */

export function setDesignTemplate(data) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/template-standard/use",
    method: 'post',
    data: data
  });
}
/**
 * 根据分类，查询图标库列表
 * @param {Number} styleNum
 */

export function getStyleIcon(styleNum) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/app-category-class/images?iconStyle=".concat(styleNum),
    method: 'get'
  });
}
export function updateRole(data) {
  return request({
    url: '/p2/api/v1/weapp/manager/user/update_role',
    method: "PUT",
    data: data
  });
}
/**
 * 根据应用id，保存底部自定义导航
 * @param {Object} data
 */

export function saveBottom(appid, appType, data) {
  return request({
    url: "/p1/api/v1/weapp/apply/footer-category?appid=".concat(appid, "&appType=").concat(appType),
    method: "POST",
    data: data
  });
}
/**
 * 根据应用id，保存顶部自定义导航
 * @param {Object} data
 */

export function saveTopNav(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/header-nav",
    method: "PUT",
    data: data
  });
}
/**
 * 根据应用id，查询底部自定义导航应用列表
 */

export function loadBottom(appid, appType) {
  return request({
    url: "/p1/api/v1/weapp/apply/footer-category?appid=".concat(appid, "&appType=").concat(appType),
    method: "GET"
  });
}
/**
 * 根据应用id，查询顶部自定义导航应用列表
 */

export function loadTopNav(appType) {
  return request({
    url: "/p1/api/v1/weapp/manager/header-nav?appType=".concat(appType),
    method: "GET"
  });
}
/**
 * 查询操作日志列表
 * @param {Object} page
 */

export function searchOperationLog(data) {
  return request({
    url: "/p1/api/v1/weapp/operation-log/search?page=".concat(data.page),
    method: "POST",
    data: data
  });
}
export function getDesinerClassList(page, data) {
  var size = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;
  return request({
    url: "/p1/api/v1/weapp/manager/class/search?page=".concat(page, "&size=").concat(size),
    method: "POST",
    data: data
  });
}