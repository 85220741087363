import request from '@/utils/request'; // 获取微页面列表

export function getMicroList(page, searchType, pageMode) {
  var url = "/p1/api/v1/weapp/manager/micro-pages?page=".concat(page, "&searchType=").concat(searchType);

  if (pageMode != null) {
    url += "&pageMode=".concat(pageMode);
  }

  return request({
    url: url,
    method: "GET"
  });
} // 編輯微页面列表

export function editMicroList(id, title) {
  return request({
    url: "/p1/api/v1/weapp/manager/micro-pages/".concat(id),
    method: "PUT",
    data: {
      title: title
    }
  });
} // 删除微页面数据

export function delMicroListItem(id) {
  return request({
    url: "/p1/api/v1/weapp/manager/micro-pages/".concat(id),
    method: "DELETE"
  });
} // 发布微页面数据

export function publishMicroListItem(id) {
  return request({
    url: "/p1/api/v1/weapp/manager/micro-pages/".concat(id, "/publish"),
    method: "PUT"
  });
} // 获取微页面模板列表

export function getMicroModuleList(page, pageMode) {
  return request({
    url: "/p1/api/v1/weapp/manager/micro-pages/sys-templates?page=".concat(page, "&pageMode=").concat(pageMode),
    method: "GET"
  });
} // 复制微页面

export function copyMicroModule(id) {
  return request({
    url: "/p1/api/v1/weapp/manager/micro-pages/".concat(id, "/copy"),
    method: "POST"
  });
} // 空白创建

export function createEmptyMicro(data) {
  return request({
    url: "/p1/api/v1/weapp/manager/micro-pages",
    method: "POST",
    data: data
  });
} // 使用模板

export function useMicroModule(id) {
  return request({
    url: "/p1/api/v1/weapp/manager/micro-pages/".concat(id, "/copy"),
    method: "POST"
  });
} // 小程序码

export var appletCode = function appletCode(id) {
  return request({
    url: "/p1/api/v1/weapp/manager/micro-pages/".concat(id, "/wxa-code"),
    method: "GET"
  });
};
export function downloadFiles(filePath, fileName) {
  fetch(filePath).then(function (res) {
    return res.blob();
  }).then(function (blob) {
    var a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a); //追加a标签

    a.click(); //a标签点击

    document.body.removeChild(a); //移除节点
  });
}