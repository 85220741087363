import _objectSpread from "D:/third/openy-wxms-web2021-01-12/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es6.regexp.replace";
export var wxConfigMix = {
  methods: {
    wxShareConfig: function wxShareConfig(single, line, data) {
      var AppMessage = {};
      var Timeline = {};

      if (single) {
        if (single.desc) single.desc = single.desc.replace(/<[^<>]+>/g, "");
        if (single.desc) single.desc = single.desc.replace(/<[^<>]+>/g, "");
        AppMessage = single;
      } else {
        AppMessage = {
          title: '表单名称',
          // 分享标题
          desc: '欢迎使用调查问卷！这是一份示例问卷。',
          // 分享描述
          link: process.env.VUE_APP_API_URL_P2,
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: process.env.VUE_APP_BASE_IMG + "/wxms-question-form-img1.png?imageMogr2/auto-orient/strip|imageView2/1/w/176/h/104" // 分享图标

        };
      }

      if (line) {
        Timeline = line;
      } else {
        Timeline = {
          title: '表单名称',
          // 分享标题
          link: process.env.VUE_APP_API_URL_P2,
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: process.env.VUE_APP_BASE_IMG + "/wxms-question-form-img1.png?imageMogr2/auto-orient/strip|imageView2/1/w/176/h/104" // 分享图标

        };
      }

      window.wx.updateAppMessageShareData(_objectSpread(_objectSpread({}, AppMessage), {}, {
        success: function success() {// 设置成功
        }
      }));
      window.wx.updateTimelineShareData(_objectSpread(_objectSpread({}, Timeline), {}, {
        success: function success() {// 设置成功
        }
      }));
    }
  }
};