import Layout from "../layout/index";
export default [{
  path: '/',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component() {
    return import(
    /* webpackChunkName: "home" */
    '@/views/landingPage/index');
  },
  hidden: true
}, {
  path: '/messagelist',
  component: Layout,
  name: "首页",
  children: [{
    path: 'mini',
    component: function component() {
      return import(
      /* webpackChunkName: "mini-messagelist-set" */
      '@/views/messagelist.vue');
    },
    name: 'Dashboard'
  }]
}, {
  path: '/mini',
  component: Layout,
  hidden: true,
  name: "主页",
  children: [{
    path: 'message',
    component: function component(resolve) {
      return require(['@/views/message'], resolve);
    }
  }]
}, {
  path: '/home-class',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component() {
    return import(
    /* webpackChunkName: "home-class" */
    '@/views/landingPage/home-class');
  },
  hidden: true
}, {
  path: '/caseShow',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/caseShow'], resolve);
  },
  hidden: true
}, {
  path: '/activity',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/activity'], resolve);
  },
  hidden: true
}, {
  path: '/home-reservation',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/reservation'], resolve);
  },
  hidden: true
}, {
  path: '/home-datum',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/datum'], resolve);
  },
  hidden: true
}, {
  path: '/home-product',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/product'], resolve);
  },
  hidden: true
}, {
  path: '/home-from',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/from'], resolve);
  },
  hidden: true
}, {
  path: '/home-circle',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/circle'], resolve);
  },
  hidden: true
}, {
  path: '/collection',
  meta: {
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/collection'], resolve);
  },
  hidden: true
}, {
  path: '/home-price',
  meta: {
    title: '开问-价格服务',
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/home-price'], resolve);
  },
  hidden: true
}, {
  path: '/home-cooper',
  meta: {
    title: '开问-合作伙伴',
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/home-cooper'], resolve);
  },
  hidden: true
}, {
  path: '/home-h5-qr',
  meta: {
    title: '落地页案例展示',
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/landingPage/home-h5-qr-page'], resolve);
  },
  hidden: true
}, {
  path: '/help',
  meta: {
    title: '帮助中心',
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  component: function component(resolve) {
    return require(['@/views/help/index'], resolve);
  },
  hidden: true
}, {
  path: '/trial',
  meta: {
    title: '快速创建试用小程序',
    noCache: true,
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/mini/trial/index'], resolve);
  },
  hidden: true
}, {
  path: '/user/agreement',
  meta: {
    title: '用户协议',
    noCache: true,
    windowStyle: "#app,html,body{height:100%;background: #F5F6F7}",
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/headManagement/protocol/user-agreement'], resolve);
  },
  hidden: true
}, {
  path: '/privacy/agreement',
  meta: {
    title: '隐私协议',
    noCache: true,
    windowStyle: "#app,html,body{height:100%;background: #F5F6F7}",
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/headManagement/protocol/privacy-agreement'], resolve);
  },
  hidden: true
}, {
  path: '/transfer/privatization-mini',
  meta: {
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/headManagement/transfer/privatization-mini'], resolve);
  },
  hidden: true
}, {
  path: '/reservation/create-template',
  meta: {
    title: '预约',
    noCache: true,
    windowStyle: "#app,html,body{height:100%;background: #F5F6F7}"
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/reservation/create-template'], resolve);
  },
  hidden: true
}, // 创建预约页
{
  path: '/reservation-create/:id',
  meta: {
    title: '预约',
    noCache: true,
    windowStyle: "#app,html,body{height:100%;background: #F5F6F7}"
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/reservation/reservation-create'], resolve);
  },
  hidden: true
}, // 创建查询
{
  path: '/query-create',
  meta: {
    title: '创建查询',
    noCache: true,
    windowStyle: "#app,html,body{height:100%;background: #F5F6F7}"
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/query/create'], resolve);
  },
  hidden: true
}, //创建微课
{
  path: '/minlesson-create/:id',
  meta: {
    title: '创建微课',
    noCache: true,
    windowStyle: "#app,html,body{height:100%;background: #F5F6F7}"
  },
  component: function component(resolve) {
    return require(['@/views/mini/microLesson/create'], resolve);
  },
  hidden: true
}];