var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "terrace-wrapper" },
    [
      _c("navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "terrace-box" }, [
        _c(
          "div",
          { staticClass: "terrace-menu" },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-vertical-demo",
                attrs: {
                  "default-active": _vm.$route.path,
                  "active-text-color": "#333333",
                  router: true
                }
              },
              _vm._l(_vm.menus, function(menu, m) {
                return _c(
                  "el-menu-item",
                  { key: "m" + m, attrs: { index: menu.path } },
                  [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(menu.meta.title))
                    ])
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "terrace-main" },
          [
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [_c("router-view")],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }