import "core-js/modules/es6.function.name";

/**
 * 设置sessionStorage
 * @param {String} key sessionStorage名称
 * @param {*} value 值
 */
export function setSessionStorage(key, value) {
  sessionStorage.setItem(key, value); // 存入一个值
}
;
/**
 * 读取sessionStorage
 * @param {String} name sessionStorage名称
 */

export function getSessionStorage(name) {
  var res = sessionStorage[name];

  if (res) {
    return res;
  } else {
    return "";
  }
}
;
/**
 * 删除sessionStorage
 * @param {String} key sessionStorage名称
 */

export function delSessionStorage(key) {
  sessionStorage.removeItem(key);
}
;