import request from '@/utils/request';
/**
 * 查询我的问卷列表(全部)
 * @param {Object} page
 * @param {Object} keyword
 * @param {Object} appid
 */

export function getMySurvey(page, keyword, searchType, appid) {
  var obj = {
    page: page,
    keywords: keyword,
    searchType: searchType
  };
  return request({
    url: "/p1/api/v1/weapp/blong/survey/search?page=".concat(page),
    // url: `/p1/api/v1/weapp/manager/survey/search?page=${page}`,
    method: 'post',
    data: obj
  });
}
/**
 * 查询我的问卷列表(已授权)
 * @param {Object} page
 * @param {Object} keyword
 * @param {Object} appid
 */

export function getMySurvey2(page, keyword, appid) {
  var obj = {
    page: page,
    keywords: keyword
  };
  return request({
    url: "/p1/api/v1/weapp/authed/survey/search?page=".concat(page),
    method: 'post',
    data: obj
  });
}
export function getMySurveyO(page, data) {
  return request({
    url: "/p1/api/v1/my-survey/search?page=".concat(page),
    method: 'post',
    data: data
  });
} //查询我的问卷列表

export function getMiniSurvey(page, data) {
  return request({
    url: "/p1/api/v1/weapp/manager/survey/search?page=".concat(page),
    method: 'post',
    data: data
  });
} //发布问卷

export function issueSurvey(id) {
  return request({
    url: "/p1/api/v1/survey/publish",
    method: 'put',
    data: {
      id: id
    }
  });
} //暂停问卷

export function stopSurvey(id) {
  return request({
    url: "/p1/api/v1/survey/pause",
    method: 'put',
    data: {
      id: id
    }
  });
} //复制问卷

export function copySurveys(id) {
  return request({
    url: "/p1/api/v1/survey/copy",
    method: 'post',
    data: {
      id: id
    }
  });
} //删除问卷

export function deleteSurveys(id) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/delete"),
    method: 'delete'
  });
} //查询试卷引导页状态接口

export function getSurveyState(id) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/answer-home-page/info"),
    method: 'get'
  });
} //查询问卷详情接口

export function getSurveyAnswer(id) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/answer-question-page/info"),
    method: 'get'
  });
} //提交问卷填选的信息

export function submitAnswer(data, url) {
  return request({
    url: url,
    method: 'post',
    data: data
  });
} //查询预览问卷详情接口

export function getSurveyAnswerLook(id) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/detail"),
    method: 'get'
  });
} //创建空白问卷

export function createBlankSurvey(data) {
  return request({
    url: "/p1/api/v1/survey/create-blank",
    method: 'post',
    data: data
  });
} //创建空白问卷

export function updateSurvey(data) {
  return request({
    url: "/p1/api/v1/survey/update",
    method: 'put',
    data: data
  });
} //添加问卷题

export function addSurvey(data) {
  return request({
    url: "/p1/api/v1/survey/question",
    method: 'post',
    data: data
  });
} //删除问卷题

export function deleteSurvey(id) {
  return request({
    url: "/p1/api/v1/survey/question?id=".concat(id),
    method: 'delete'
  });
} //复制问卷题

export function copySurvey(id) {
  return request({
    url: "/p1/api/v1/survey/question/copy",
    method: 'post',
    data: {
      id: id
    }
  });
} //查询问卷下的数据列表

export function getSurveyList(id, page) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/answer/list?page=").concat(page),
    method: 'get'
  });
} //导出问卷下的数据

export function getSurveyExport(id) {
  return request({
    url: "/p1/api/v1/survey/answer/export?id=".concat(id),
    method: 'get',
    responseType: 'blob'
  });
}
/**
 * 导出统计报表的Excel
 * @param {Object} id
 */

export function getStatExport(id) {
  return request({
    url: "/p1/api/v1/survey/answer/stat/export?id=".concat(id),
    method: 'get',
    responseType: 'blob'
  });
} //查询用户答题结果

export function getSurveyResult(id) {
  return request({
    url: "/p1/api/v1/survey/answer/".concat(id, "/detail"),
    method: 'get'
  });
} //查询用户答题结果

export function getTemplateTitle(surveyId) {
  return request({
    url: "/p1/api/v1/survey/template/questions-text/list?surveyId=".concat(surveyId),
    method: 'get'
  });
} //修改一道题

export function changeQuestion(data) {
  return request({
    url: "/p1/api/v1/survey/question",
    method: 'put',
    data: data
  });
} //题目排序

export function sortQuestion(data) {
  return request({
    url: "/p1/api/v1/survey/question/sort",
    method: 'put',
    data: data
  });
} //查询模板分类

export function getTemplateList(type) {
  return request({
    url: "/p1/api/v1/survey/template-class/list?type=".concat(type),
    method: 'get'
  });
} //查询问卷模板列表

export function getTemplateLists(data, page) {
  return request({
    url: "/p1/api/v1/survey/template/list?page=".concat(page),
    method: 'post',
    data: data
  });
} //根据模板创建问卷

export function createByTemplate(data) {
  return request({
    url: "/p1/api/v1/survey/create-by-template",
    method: 'post',
    data: data
  });
} //问卷分享信息

export function getShareMessage(itemId, itemType) {
  return request({
    url: "/p2/api/v1/weapp/share-message?itemId=".concat(itemId, "&itemType=").concat(itemType),
    method: 'get'
  });
} //第三方小程序分享信息

export function getMiniShareMessage(itemId, itemType, appid) {
  return request({
    url: "/p2/api/v2/weapp/poster-message?itemId=".concat(itemId, "&itemType=").concat(itemType),
    headers: {
      appid: appid
    },
    method: 'get'
  });
} //获取问卷皮肤列表

export function getSkinList() {
  return request({
    url: "/p1/api/v1/survey/theme/list",
    method: 'get'
  });
} //保存皮肤

export function saveSkin(data) {
  return request({
    url: "/p1/api/v1/survey/set-theme",
    method: 'put',
    data: data
  });
} //开始答题

export function beginAnswerRet(data) {
  return request({
    url: "/p1/api/v1/survey/exam/answer/start",
    method: 'post',
    data: data
  });
} //查看问卷统计

export function loadSurveyStatData(id) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/stat-data"),
    method: 'get'
  });
} //查看正确答案

export function loadSurveyAnalysisData(id) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/exam/show-answer"),
    method: 'get'
  });
} //自动保存

export function autosaveFun(data) {
  return request({
    url: "/p1/api/v1/survey/exam/answer/auto-submit",
    method: 'post',
    data: data
  });
} //自动保存

export function surveySettings(method, id, data) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/settings"),
    method: method,
    data: data
  });
}
export function getEditSurveyAnswer(surveyId) {
  return request({
    url: "/p1/api/v1/survey/answer-edit/answer-question-page/info?surveyId=".concat(surveyId),
    method: 'get'
  });
} //查看统计报表

export function loadStatistical(id) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/stat-data"),
    method: 'get'
  });
} //根据班级公告id，查询预览试卷信息（打卡）

export function loadSurveyPreview1(id) {
  return request({
    url: "/p1/api/v1/weapp/class/notify-user/".concat(id, "/answer-detail"),
    method: 'get'
  });
} //根据班级公告id，查询预览试卷信息（调查）

export function loadSurveyPreview2(id) {
  return request({
    url: "/p1/api/v1/weapp/class/notify-user/".concat(id, "/survey/answer-detail"),
    method: 'get'
  });
} //根据班级公告id，查询预览试卷信息（测评）

export function loadSurveyPreview3(id) {
  return request({
    url: "/p1/api/v1/weapp/class/notify-user/".concat(id, "/exam/answer-detail"),
    method: 'get'
  });
} //更换问卷标题图

export function changeQuestionTopImg(data) {
  return request({
    url: "/p1/api/v1/survey/survey-img",
    method: 'put',
    data: data
  });
} // 获取历史记录

export function getHistoryList(surveyId, page) {
  return request({
    url: "/p1/api/v1/survey/".concat(surveyId, "/answer-history/list?page=").concat(page),
    method: "GET"
  });
} // 删除记录

export function deleteHistory(id) {
  return request({
    url: "/p1/api/v1/survey/answer/".concat(id),
    method: "DELETE"
  });
}
/**
 * 增加自填表格题下的一个子题,支持空题，默认填空题
 * @param {Object} data
 */

export function addSelfTableSingle(data) {
  return request({
    url: "/p1/api/v1/survey/question/form-item",
    method: 'POST',
    data: data
  });
}
/**
 * 修改自填表格题下的子题
 * @param {Object} data
 */

export function editSelfTableSingle(data) {
  return request({
    url: "/p1/api/v1/survey/question/form-item",
    method: 'PUT',
    data: data
  });
}
/**
 * 自填表格题下的子题排序
 * @param {Object} data
 */

export function orderSelfTableSingle(data) {
  return request({
    url: "/p1/api/v1/survey/question/form-item/sort",
    method: 'PUT',
    data: data
  });
}
/**
 * 查询自填表格题下的子题
 * @param {String} id
 */

export function findSelfTableSingle(id) {
  return request({
    url: "/p1/api/v1/survey/question/form-item?id=".concat(id),
    method: 'GET'
  });
}
/**
 * 删除自填表格题下的一个子题
 * @param {String} id
 */

export function delSelfTableSingle(id) {
  return request({
    url: "/p1/api/v1/survey/question/form-item?id=".concat(id),
    method: 'DELETE'
  });
}
/**
 * 查询通知待办详情
 */

export function getTodoInfo(id) {
  return request({
    url: "/p1/api/v1/weapp/manager/todo-audit/".concat(id, "/detail"),
    method: 'GET'
  });
} // 收藏

export function favoritedFn(id, type, boll) {
  return request({
    url: "/p1/api/v1/weapp/content/favorite",
    method: 'POST',
    data: {
      itemId: id,
      itemType: type,
      favorited: !boll
    }
  });
} // 生成专栏

export function createSpecial(id) {
  return request({
    url: "/p1/api/v1/survey/".concat(id, "/build-weapp-class"),
    method: 'POST'
  });
} // 查询表单可作为唯一标记的题目列表，用于设置表单导入规则

export function getQueryCollist(id, type) {
  return request({
    url: "/p1/api/v1/query/col-list?itemType=".concat(type, "&itemId=").concat(id),
    method: 'GET'
  });
}
/**
 * 校验多级下拉题的格式
 * @returns 
 */

export function checkMList(data) {
  return request({
    url: "/p1/api/v1/survey/cascade/check-format",
    method: 'POST',
    data: data
  });
}