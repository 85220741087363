import "core-js/modules/es6.regexp.match";
import "D:\\third\\openy-wxms-web2021-01-12\\node_modules\\.store\\core-js@2.6.12\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "D:\\third\\openy-wxms-web2021-01-12\\node_modules\\.store\\core-js@2.6.12\\node_modules\\core-js\\modules\\es6.promise.js";
import "D:\\third\\openy-wxms-web2021-01-12\\node_modules\\.store\\core-js@2.6.12\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "D:\\third\\openy-wxms-web2021-01-12\\node_modules\\.store\\core-js@2.6.12\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui'; //

import 'mavon-editor/dist/css/index.css'; // 权限指令

import "./assets/styles/element-variables.scss"; // global css

import "./assets/styles/index.scss";
import "./assets/iconfont/iconfont.css"; // 代码高亮

import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/atom-one-dark.css';
import App from "./App";
import store from "./store";
import router from "./router";
import "./router/index";
import "./assets/styles/animate.css"; // import './assets/styles/variables.scss';
// 内容分享

import shareZload from "./plugins/share/index.js";
Vue.prototype.$sharePop = shareZload; // 设计器预览

import previewComponent from "./plugins/preview/index.js";
Vue.prototype.$previewComponent = previewComponent; // 内容选择

import popSelect from "./plugins/popSelect";
Vue.prototype.$popSelect = popSelect; // 视频播放器

import VideoPlayer from 'vue-video-player';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css';
Vue.use(VideoPlayer); // 判断手机系统

Vue.prototype.$browser = {
  versions: function () {
    var u = navigator.userAgent;
    return {
      //移动终端浏览器版本信息
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      //ios终端
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
      //android终端或uc浏览器
      iPhone: u.indexOf('iPhone') > -1,
      //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf('iPad') > -1,
      //是否iPad
      iosSwitch: false
    };
  }()
};
Vue.prototype.$isMobile = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? true : false;
import axios from "axios";
Vue.prototype.axios = axios;
import { VueJsonp } from 'vue-jsonp';
Vue.use(VueJsonp); // 导入极验

require('@/assets/gt.js'); // 绑定到原型


Vue.prototype.$initGeet = initGeetest;
Vue.prototype.$isWeiXin = window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger' ? true : false;
Vue.prototype.$IMG_DOMAIN = process.env.VUE_APP_BASE_IMG;
Vue.use(VueHighlightJS);
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size

}); // 子组件间的通讯

import Bus from "./utils/bus";
Vue.prototype.$bus = Bus;
import { wxConfigMix } from "./mixins/share";
Vue.mixin(wxConfigMix);
import dataV from '@jiaminghi/data-view';
Vue.use(dataV);
Vue.config.productionTip = false;
import xss from 'xss'; // 三、定义全局xss方法

Object.defineProperty(Vue.prototype, '$xss', {
  value: xss
});
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}); // if (window.location.origin != 'https://open.openwhy.cn') {
//   import("vconsole")
//     /* webpackChunkName: "vconsole" */
//     .then(vConsole => {
//       new vConsole.default();
//     });
// }