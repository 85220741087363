import "core-js/modules/es6.function.name";
import _toConsumableArray from "D:/third/openy-wxms-web2021-01-12/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import Vue from 'vue';
import axios from 'axios';
import { Message } from 'element-ui';
import Router from 'vue-router';
import store from '@/store';
import Config from '@/settings';
import NProgress from 'nprogress'; // progress bar

import 'nprogress/nprogress.css'; // progress bar style

import { getToken } from '@/utils/auth'; // getToken from cookie

NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

Vue.use(Router);
import children1 from "./children1";
import children2 from "./children2";
import terrace from "./terrace";
var routes = [{
  path: '/login',
  meta: {
    noCache: true,
    isNoLogin: true,
    windowStyle: "#app,html,body{height:100%;}"
  },
  component: function component() {
    return import(
    /* webpackChunkName: "login" */
    '@/views/login/index');
  },
  hidden: true
}, {
  path: '/:type/login',
  meta: {
    title: 'wlb-登录',
    noCache: true,
    isNoLogin: true,
    windowStyle: "#app,html,body{height:100%;}"
  },
  name: 'thridLogin',
  component: function component() {
    return import(
    /* webpackChunkName: "login" */
    '@/views/login/thridLogin');
  },
  hidden: true
}].concat(_toConsumableArray(children1), _toConsumableArray(children2), _toConsumableArray(terrace), [{
  path: '/404',
  meta: {
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/common/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  meta: {
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/common/401'], resolve);
  },
  hidden: true
}]);
var routerObj = new Router({
  mode: 'history',
  base: '/',
  routes: routes
});

var setWindowStyle = function setWindowStyle(v) {
  if (!document.getElementById('windowStyle')) {
    var _style = document.createElement('style');

    _style.id = "windowStyle";
    _style.type = "text/css";
    document.head.appendChild(_style);
  }

  document.getElementById('windowStyle').innerHTML = v || "";
};

routerObj.beforeEach(function (to, from, next) {
  if (to.query.appId) {
    sessionStorage.setItem('webId', to.query.appId);
  }

  if (process.env.VUE_APP_ICO !== '') {
    var link = document.querySelector('link[rel*="icon"]');
    link.href = process.env.VUE_APP_ICO;
  }

  var outType = sessionStorage.getItem("platformType") || '';
  NProgress.start();

  if (to.path === '/') {
    if (outType !== '' && from.path != '/') {
      next("/".concat(outType, "/login"));
    } else {
      axios({
        url: process.env.VUE_APP_BASE_API + "/api/v1/platform-home-page-type",
        method: "GET",
        hideToast: false //隐藏报错信息

      }).then(function (e) {
        if (e.data.code == '000000') {
          var res = e.data.datas;
          sessionStorage.removeItem('platformType');

          if (res.platformHomePageType * 1 == 1) {
            next();
          } else {
            if (to.query && to.query.redirect) {
              next('/login?redirect=' + to.query.redirect);
            } else {
              next('/login');
            }
          }

          NProgress.done();
        }
      });
    }
  } else {
    if (getToken()) {
      // 已登录且要跳转的页面是登录页
      if (to.path === '/login' || to.name == 'thridLogin') {
        if (to.name == 'thridLogin') {
          sessionStorage.setItem('platformType', to.params.type);
        }

        next('/messagelist/mini');
        NProgress.done();
      } else {
        store.dispatch('GetInfo').then();
        next();
      }
    } else {
      if (to.meta.isNoLogin) {
        // 免登录
        next();
      } else {
        if (outType !== '') {
          next("/".concat(outType, "/login/?redirect=").concat(to.fullPath));
        } else {
          next("/?redirect=".concat(to.fullPath));
        }

        NProgress.done();
      }
    }
  }
});
routerObj.afterEach(function (to) {
  NProgress.done(); // finish progress bar

  var windowStyle = to.meta.windowStyle;
  setWindowStyle(windowStyle); // 该页面父级高度（主要用于该页面是否是撑满一屏展示）

  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = process.env.VUE_APP_NAME || '加载中...';
  } // if (true) {


  if (to.meta.wxConfig && Vue.prototype.$isWeiXin) {
    var url = "".concat(location.origin).concat(to.fullPath); //拼接当前页面地址

    axios({
      url: process.env.VUE_APP_BASE_API2 + "/api/v1/share-messages/wx",
      method: "POST",
      hideToast: false,
      //隐藏报错信息
      data: {
        url: url
      }
    }).then(function (e) {
      if (e.data.code == '000000') {
        var data = e.data.datas;
        window.wx.config({
          debug: false,
          appId: data.appid,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          openTagList: ["wx-open-launch-weapp"],
          jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'updateAppMessageShareData', 'updateTimelineShareData']
        });
        window.wx.ready(function () {
          setTimeout(function () {
            if (to.meta.browserPage) {
              //当前页面为浏览器页面时显示所有功能按钮
              window.wx.showAllNonBaseMenuItem();
            } else {
              //当前页面不是浏览器页面时显示部分按钮
              window.wx.hideAllNonBaseMenuItem();
              window.wx.showMenuItems({
                menuList: ["menuItem:share:appMessage", "menuItem:share:timeline", "menuItem:share:qq", "menuItem:share:weiboApp", 'menuItem:share:QZone'] // 要显示的菜单项，所有menu项见附录3

              });
            }
          }, 300);
        });
      } else {
        Message.error({
          message: "签名接口异常！"
        });
      }
    });
  }
});
export default routerObj;