import Vue from 'vue';
import popUp from "./index.vue";
var selectConstructor = Vue.extend(popUp);

var popSelect = function popSelect(type, name) {
  var instance = new selectConstructor();
  instance.$mount(); // 可以在这里通过options参数传递一些自定义参数给弹窗组件
  // 将弹窗组件添加到页面中

  document.body.appendChild(instance.$el); // 返回一个Promise对象，可以使用.then()方法获取传递给弹窗的参数

  return new Promise(function (resolve, reject) {
    instance.onOpenFn(type, name);

    instance.onSelect = function (value) {
      resolve(value);
      instance.onCancel();
    };
  });
};

export default popSelect;