import Vue from 'vue';
import previewPop from "./preview";
var PopupConstructor = Vue.extend(previewPop);

var previewComponent = function previewComponent(id, type, obj) {
  var instance = new PopupConstructor();
  instance.$mount(); // 可以在这里通过options参数传递一些自定义参数给弹窗组件
  // 将弹窗组件添加到页面中

  document.body.appendChild(instance.$el); // 返回一个Promise对象，可以使用.then()方法获取传递给弹窗的参数

  return new Promise(function (resolve, reject) {
    instance.open(id, type, obj);

    instance.onConfirm = function (value) {
      instance.onCancel();
      resolve(value);
    };
  });
};

export default previewComponent;