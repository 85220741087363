import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Navbar from "./components/Navbar.vue";
export default {
  components: {
    Navbar: Navbar
  },
  mounted: function mounted() {
    var str_css = "#app {height: 100%}";
    var style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = str_css;
    document.getElementsByTagName("HEAD").item(0).appendChild(style);
  },
  computed: {
    menus: function menus() {
      var _this = this;

      var arr = this.$router.options.routes.filter(function (e) {
        return e.path === '/terrace';
      });

      if (arr[0] && arr[0].children && arr[0].children.length > 0) {
        return arr[0].children.filter(function (item) {
          return _this.$store.state.user.volunteer.includes(item.meta.role);
        });
      } else {
        return [];
      }
    }
  },
  destroyed: function destroyed() {
    var str_css = "#app {height: auto}";
    var style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = str_css;
    document.getElementsByTagName("HEAD").item(0).appendChild(style);
  }
};