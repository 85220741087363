import "core-js/modules/es6.regexp.split";
import Cookies from 'js-cookie';
import Config from '@/settings';
var TokenKey = Config.TokenKey;
export function getToken() {
  if (Cookies.get(TokenKey)) {
    return "Bearer " + Cookies.get(TokenKey);
  } else {
    return "";
  }
}
export function setToken(token) {
  var domain = getDomain(); // if (process.env.NODE_ENV === 'production') {

  return Cookies.set(TokenKey, token, {
    expires: Config.tokenCookieExpires,
    domain: domain
  }); // } else {
  //   return Cookies.set(TokenKey, token, { expires: Config.tokenCookieExpires })
  // }
}
export function removeToken() {
  var domain = getDomain();
  console.log(domain); // if (process.env.NODE_ENV === 'production') {

  return Cookies.remove(TokenKey, {
    domain: domain
  }); // } else {
  //   return Cookies.remove(TokenKey)
  // }
}

function getDomain() {
  var domain = process.env.NODE_ENV === 'production' ? location.host : location.hostname;
  if (domain == 'localhost') return domain;
  var array = domain.split(".");

  if (domain.split('.').length > 2) {
    domain = array[array.length - 2] + "." + array[array.length - 1];
  }

  return domain;
}