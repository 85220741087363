var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "dialog-overlay" }, [
        _c("div", { staticClass: "dialog-content" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "preview-view"
            },
            [
              _c("iframe", {
                attrs: {
                  src: _vm.preViewUrl,
                  width: "100%",
                  height: "100%",
                  frameborder: "0"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "preview-msg" }, [
            _c("div", { staticClass: "applet-msg-list" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.type == 1
                      ? "网站"
                      : _vm.type == 2
                      ? "小程序"
                      : _vm.type == 3
                      ? "专栏"
                      : "模板"
                  ) + "名称："
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "applet-msg" }, [
                !_vm.isEdit
                  ? _c(
                      "div",
                      { style: { color: _vm.type == 2 ? "#666" : "#333" } },
                      [_vm._v(_vm._s(_vm.name))]
                    )
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.name,
                          expression: "name"
                        }
                      ],
                      staticClass: "edit-name",
                      attrs: { type: "text" },
                      domProps: { value: _vm.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.name = $event.target.value
                        }
                      }
                    }),
                _vm._v(" "),
                _vm.type == 1
                  ? _c("span", { on: { click: _vm.handleClickEdit } }, [
                      _vm._v(_vm._s(_vm.isEdit ? "保存" : "编辑"))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.type == 3
                  ? _c("span", { on: { click: _vm.handleClickEditSpecial } }, [
                      _vm._v(_vm._s(_vm.isEdit ? "保存" : "编辑"))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.type != 3
              ? _c("div", [
                  _vm.type == 1
                    ? _c("div", { staticClass: "applet-msg-list" }, [
                        _c("p", [_vm._v("网站链接：")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "applet-msg" }, [
                          _c("div", [_vm._v(_vm._s(_vm.copy_url))]),
                          _vm._v(" "),
                          _c("span", { on: { click: _vm.copyPerviewLink } }, [
                            _vm._v("复制")
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type != 3
                    ? _c("div", { staticClass: "applet-msg-list" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.type == 1
                                ? "网站"
                                : _vm.type == 2
                                ? "小程序"
                                : ""
                            ) + "二维码："
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "applet-code-box" }, [
                          _vm.type == 1 || _vm.type == 3 || _vm.type == 4
                            ? _c("div", {
                                ref: "exCodeRef",
                                staticClass: "applet-code"
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type == 2
                            ? _c("div", { staticClass: "applet-code" }, [
                                _c("img", {
                                  attrs: { src: _vm.appletCode, alt: "" }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [_vm._v("请用微信扫码查看")])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == 1
                    ? _c(
                        "div",
                        {
                          staticClass: "download-btn",
                          on: { click: _vm.downloadImg }
                        },
                        [_vm._v("下载")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == 4
                    ? _c(
                        "div",
                        {
                          staticClass: "download-btn",
                          on: { click: _vm.handleClickUseModule }
                        },
                        [_vm._v("使用模板")]
                      )
                    : _vm._e()
                ])
              : _c("div", { staticClass: "special-code-wrap" }, [
                  _c("div", { staticClass: "applet-msg-list" }, [
                    _c("p", [_vm._v("手机网站二维码：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "special-code-box applet-code-box" },
                      [
                        _c("div", {
                          ref: "exCodeRef",
                          staticClass: "applet-code"
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "option-btn",
                        on: { click: _vm.downloadImg }
                      },
                      [_vm._v("下载")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "option-btn",
                        on: { click: _vm.copyPerviewLink }
                      },
                      [_vm._v("复制链接")]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.appletCode
                    ? _c("div", { staticClass: "applet-msg-list" }, [
                        _c("p", [_vm._v("小程序二维码：")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "special-code-box applet-code-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "applet-code",
                                attrs: { id: "apple-qrcode" }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "data:image/jpeg;base64," +
                                      _vm.appletCode,
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "option-btn",
                            on: { click: _vm.downloadAppetImg }
                          },
                          [_vm._v("下载")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "option-btn",
                            on: { click: _vm.copyAppletPerviewLink }
                          },
                          [_vm._v("复制路径")]
                        )
                      ])
                    : _vm._e()
                ])
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "el-icon-close close",
            on: {
              click: function($event) {
                _vm.show = false
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.copy_url,
                expression: "copy_url"
              }
            ],
            staticClass: "copy-input",
            attrs: { type: "text", id: "copy" },
            domProps: { value: _vm.copy_url },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.copy_url = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.copy_applet_url,
                expression: "copy_applet_url"
              }
            ],
            staticClass: "copy-input",
            attrs: { type: "text", id: "applet-copy" },
            domProps: { value: _vm.copy_applet_url },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.copy_applet_url = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("a", { staticClass: "copy-input", attrs: { id: "downloadImg" } })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }