import Layout from "../layout/index";
export default [{
  path: '/template',
  meta: {
    title: '问卷模板',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/template'], resolve);
  },
  hidden: true
}, {
  path: '/from/module',
  meta: {
    title: '表单模板',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/from-module'], resolve);
  },
  hidden: true
}, {
  path: '/micro/module',
  meta: {
    title: '微页面模板',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/micro/micro-module'], resolve);
  },
  hidden: true
}, {
  path: '/create',
  meta: {
    title: '问卷创建',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/create'], resolve);
  },
  hidden: true
}, {
  path: '/share',
  meta: {
    title: '问卷分享',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/share'], resolve);
  },
  hidden: true
}, {
  path: '/special/share',
  meta: {
    title: '专栏分享',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/mini/special/share'], resolve);
  },
  hidden: true
}, {
  path: '/set-skin',
  meta: {
    title: '设置主题',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/set-skin'], resolve);
  },
  hidden: true
}, {
  path: '/preview',
  meta: {
    title: '问卷分享',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/preview'], resolve);
  },
  hidden: true
}, {
  path: '/data-form',
  meta: {
    title: '数据概况',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/data-form'], resolve);
  },
  hidden: true
}, {
  path: '/surveys-statistics',
  meta: {
    title: '问卷统计',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/statistics'], resolve);
  },
  hidden: true
}, {
  path: '/notice',
  component: Layout,
  hidden: true,
  name: "通知中心",
  children: [{
    path: 'index',
    component: function component() {
      return import(
      /* webpackChunkName: "notice-index-set" */
      '@/views/notice/index.vue');
    }
  }]
}, {
  path: '/service',
  component: Layout,
  hidden: true,
  name: "客服",
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/mini/service/index'], resolve);
    }
  }]
}, {
  path: '/accountSet',
  component: Layout,
  hidden: true,
  name: "账号设置",
  children: [{
    path: '/',
    component: function component(resolve) {
      return require(['@/views/common/accountSet'], resolve);
    }
  }]
}, {
  path: '/backlog',
  component: Layout,
  hidden: true,
  name: "待办事项",
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/headManagement/backlog'], resolve);
    }
  }]
}, {
  path: '/issue',
  component: Layout,
  hidden: true,
  name: "小程序管理",
  children: [{
    path: 'message',
    component: function component(resolve) {
      return require(['@/views/issue/message'], resolve);
    }
  }]
}, {
  path: '/weapps',
  component: Layout,
  hidden: true,
  name: "weapp",
  redirect: 'noredirect',
  children: [{
    path: 'version',
    component: function component(resolve) {
      return require(['@/views/headManagement/version'], resolve);
    }
  }]
}, {
  path: '/pub-article-growth/transfer',
  meta: {
    noCache: true,
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/operationManagement/official-article-growth/transfer'], resolve);
  },
  hidden: true
}, {
  path: '/create-circle',
  meta: {
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/circle/create-page'], resolve);
  },
  hidden: true
}, {
  path: '/product-edit',
  meta: {
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/product/edit'], resolve);
  },
  hidden: true
}, {
  path: '/elegant-edit/:id',
  meta: {
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/datum/create-elegant'], resolve);
  },
  hidden: true
}, {
  path: '/collection/edit/:id',
  meta: {
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/applicationManagement/collection/edit'], resolve);
  },
  hidden: true
}, {
  path: '/edit-circle',
  meta: {
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/circle/edit-page'], resolve);
  },
  hidden: true
}, {
  path: '/invite-code',
  meta: {
    noCache: true,
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/mini/invite-code/index'], resolve);
  },
  hidden: true
}, {
  path: '/secret-article',
  component: Layout,
  hidden: true,
  name: "secret-article",
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/headManagement/secret-article/index'], resolve);
    }
  }]
}, {
  path: '/productNo',
  meta: {
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/contentManagement/product/product-no'], resolve);
  },
  hidden: true
}, {
  path: '/customer-service',
  component: Layout,
  hidden: true,
  name: "客服列表",
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/headManagement/customer-service/index'], resolve);
    }
  }]
}, {
  path: '/customer-service',
  component: Layout,
  hidden: true,
  name: "user",
  children: [{
    path: 'user',
    component: function component(resolve) {
      return require(['@/views/headManagement/customer-service/user-message'], resolve);
    }
  }]
}, {
  path: '/mini/create-prize',
  meta: {
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/operationManagement/honor-certificate/create'], resolve);
  },
  hidden: true
}, {
  path: '/mini',
  component: Layout,
  hidden: true,
  name: "prize-mod",
  children: [{
    path: 'prize-mod',
    component: function component(resolve) {
      return require(['@/views/operationManagement/honor-certificate/preview-mod'], resolve);
    }
  }]
}, {
  path: '/setting-pc-preview',
  meta: {
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/mini/setting-pc/preview-index-mod'], resolve);
  },
  hidden: true
}, {
  path: '/not-empower',
  meta: {
    title: '未授权所有权限',
    noCache: true,
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/mini/weixin-not-empower'], resolve);
  },
  hidden: true
}, {
  path: '/privilege-failed',
  meta: {
    title: '授权失败',
    noCache: true,
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/mini/weixin-not-empower/privilege-failed'], resolve);
  },
  hidden: true
}, // 
{
  path: '/smartCre/creation',
  meta: {
    windowStyle: "#app,html,body{height:100%;}",
    title: '智能创作',
    noCache: true,
    isNoLogin: true
  },
  component: function component(resolve) {
    return require(['@/views/operationManagement/smart-creation'], resolve);
  },
  hidden: true
}, {
  path: '/chat/:id',
  meta: {
    windowStyle: "#app,html,body{height:100%;}",
    title: 'AI助手',
    noCache: true,
    isNoLogin: true,
    isNoShowAi: true
  },
  name: 'taskCenter',
  component: function component(resolve) {
    return require(['@/views/operationManagement/chat'], resolve);
  },
  hidden: true
}, {
  path: '/statistics',
  meta: {
    windowStyle: "#app,html,body{height:100%;}",
    title: '统计',
    isNoLogin: true,
    isNoShowAi: true
  },
  name: 'statistics',
  component: function component(resolve) {
    return require(['@/views/operationManagement/statistics/index'], resolve);
  },
  hidden: true
}, {
  path: '/statistics/edit/:id',
  meta: {
    windowStyle: "#app,html,body{height:100%;}",
    title: '编辑统计',
    isNoLogin: true,
    isNoShowAi: true
  },
  name: 'statis-edit',
  component: function component(resolve) {
    return require(['@/views/operationManagement/statistics/edit.vue'], resolve);
  },
  hidden: true
}, {
  path: '/statistics/module',
  meta: {
    windowStyle: "#app,html,body{height:100%;}",
    title: '统计模板列表',
    isNoLogin: true,
    isNoShowAi: true
  },
  name: 'statis-edit',
  component: function component(resolve) {
    return require(['@/views/operationManagement/statistics/module.vue'], resolve);
  },
  hidden: true
}, {
  path: '/notification/:type/:id',
  meta: {
    windowStyle: "#app,html,body{background: #F5F5F5;height:100%;}",
    title: "通知/待办"
  },
  name: 'notification',
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/notification'], resolve);
  },
  hidden: true
}, {
  path: '/bulk-import/:type/:id',
  meta: {
    windowStyle: "#app,html,body{background: #F5F5F5;height:100%;}",
    title: "批量导入"
  },
  name: 'import',
  component: function component(resolve) {
    return require(['@/views/contentManagement/form/bulk-import'], resolve);
  },
  hidden: true
}, {
  path: '/redeem',
  meta: {
    windowStyle: '#app,html,body{background:linear-gradient(to bottom,#3574FF,#FFFFFF 50%);height:100%;}',
    title: "关注公众号",
    isNoLogin: true
  },
  name: "redeem",
  component: function component(resolve) {
    return require(['@/views/common/redeem'], resolve);
  },
  hidden: true
}, {
  path: '/checkList',
  meta: {
    windowStyle: "#app,html,body{background: #F5F5F5;height:100%;}",
    title: "关注公众a号",
    isNoLogin: true
  },
  name: "checkList",
  component: function component(resolve) {
    return require(['@/views/checkList'], resolve);
  },
  hidden: true
}, {
  path: '/module/edit/:id',
  meta: {
    windowStyle: "#app,html,body{background: #F5F5F5;height:100%;}",
    title: "自定义模板",
    isNoLogin: true
  },
  name: "module-edit",
  component: function component(resolve) {
    return require(['@/views/computerManagement/module-edit.vue'], resolve);
  },
  hidden: true
}, {
  path: '/pc-create',
  meta: {
    windowStyle: "#app,html,body{background: #F5F5F5;height:100%;}",
    title: "关注公众a号",
    isNoLogin: true
  },
  name: "pcCreate",
  component: function component(resolve) {
    return require(['@/views/pc-create'], resolve);
  },
  hidden: true
}];