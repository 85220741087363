import { loginPassword, getInfo, logout, loginPhoneCode, getHomeEdu } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { dataToForm } from '@/utils/index';
import { setSessionStorage, delSessionStorage } from "@/utils/get-or-set-cookie";
var user = {
  state: {
    token: getToken(),
    user: {},
    roles: [],
    volunteer: []
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_VOLUNTEER: function SET_VOLUNTEER(state, data) {
      state.volunteer = data;
    }
  },
  actions: {
    // 密码登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;

      if (userInfo.password) {
        //密码登录
        userInfo = dataToForm(userInfo);
        return new Promise(function (resolve, reject) {
          loginPassword(userInfo).then(function (res) {
            setToken(res.datas.token);
            commit('SET_TOKEN', res.datas.token);
            getInfo().then(function (res2) {
              var data;

              if (res2.datas.accountType !== 2) {
                data = {
                  user: res2.datas,
                  roles: ["test"]
                };
              } else {
                data = {
                  user: res2.datas,
                  roles: ["admin", "all:manage"]
                };
              }

              setUserInfo(data, commit); // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js

              commit('SET_LOAD_MENUS', true);
              resolve();
            });
          }).catch(function (error) {
            reject(error);
          });
        });
      } else {
        //验证码登录
        return new Promise(function (resolve, reject) {
          var userInfos = {
            mobile: userInfo.username,
            captcha: userInfo.code
          };
          loginPhoneCode(userInfos).then(function (res) {
            setToken(res.datas.token);
            commit('SET_TOKEN', res.datas.token);
            getInfo().then(function (res2) {
              var data;

              if (res2.datas.accountType !== 2) {
                data = {
                  user: res2.datas,
                  roles: ["test"]
                };
              } else {
                data = {
                  user: res2.datas,
                  roles: ["admin", "all:manage"]
                };
              }

              setUserInfo(data, commit); // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js

              commit('SET_LOAD_MENUS', true);
              resolve();
            });
          }).catch(function (error) {
            reject(error);
          });
        });
      }
    },
    //注册成功登录
    registerLogin: function registerLogin(_ref2, token) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        setToken(token);
        commit('SET_TOKEN', token);
        getInfo().then(function (res2) {
          var data;

          if (res2.datas.accountType !== 2) {
            data = {
              user: res2.datas,
              roles: ["test"]
            };
          } else {
            data = {
              user: res2.datas,
              roles: ["admin", "all:manage"]
            };
          }

          setUserInfo(data, commit); // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js

          commit('SET_LOAD_MENUS', true);
          resolve();
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        getInfo().then(function (res2) {
          var data;

          if (res2.datas.accountType !== 2) {
            data = {
              user: res2.datas,
              roles: ["test"]
            };
          } else {
            data = {
              user: res2.datas,
              roles: ["admin", "all:manage"]
            };
          }

          setUserInfo(data, commit);
          resolve(data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve, reject) {
        logout().then(function (res) {
          logOut(commit);
          resolve();
        }).catch(function (error) {
          logOut(commit);
          reject(error);
        });
      });
    },
    updateLoadMenus: function updateLoadMenus(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_LOAD_MENUS', false);
      });
    }
  }
};
export var logOut = function logOut(commit) {
  commit('SET_TOKEN', '');
  commit('SET_ROLES', []);
  removeToken();
};
export var setUserInfo = function setUserInfo(res, commit) {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.roles.length === 0) {
    commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT']);
  } else {
    commit('SET_ROLES', res.roles);
  }

  commit('SET_USER', res.user);
};
export default user;