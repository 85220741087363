import "core-js/modules/es6.regexp.to-string";
import axios from 'axios';
import router from '@/router';
import { Message } from 'element-ui';
import store from "../store";
import { getToken } from '@/utils/auth';
import Config from '@/settings';
import Cookies from 'js-cookie';
import htmlUnEscape from '@/utils/disposeData';
var hideToastAll = false; // 创建axios实例

var service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/',
  // api 的 base_url
  timeout: Config.timeout // 请求超时时间

}); // request拦截器

service.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers['X-Authorization'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改

    if (store.state.mini.applyMessage.wechatId || store.state.mini.applyMessage.websiteId) {
      config.headers['coId'] = store.state.mini.applyMessage.wechatId || store.state.mini.applyMessage.websiteId || '';
    }
  }

  var appId = sessionStorage.getItem("webId");

  if (appId && appId != "") {
    config.headers['appid'] = appId || '';
  } else {
    config.headers['appid'] = router.currentRoute.query.id || '';
  }

  config.headers['Content-Type'] = 'application/json';
  var url = config.url.substr(1, 2);

  if (url === 'p1') {
    config.baseURL = process.env.VUE_APP_BASE_API;
  } else {
    config.baseURL = process.env.VUE_APP_BASE_API2;
  }

  hideToastAll = config.hideToastAll;
  config.url = config.url.slice(3);
  config.coidAppid && (config.headers['coId'] = config.coidAppid || '');
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug

  Promise.reject(error);
}); // response 拦截器

service.interceptors.response.use(function (response) {
  var code = response.status;

  if (response.data.code === "000000" || response.data.code === '900001' || response.data.code === undefined) {
    //文件类型的返回值没有code
    return response.data;
  } else {
    if (!hideToastAll) {
      Message.error({
        message: htmlUnEscape(response.data.message)
      });
    }

    return response.data;
  }
}, function (error) {
  var code = 0;

  try {
    code = error.response.data.status;
  } catch (e) {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Message.error({
        message: '网络请求超时',
        duration: 5000
      });
      return Promise.reject(error);
    }
  }

  if (code) {
    if (code === 401) {
      store.dispatch('LogOut').then(function () {
        // 用户登录界面提示
        Cookies.set('point', 401);
        location.reload();
      });
    } else if (code === 403) {
      router.push({
        path: '/401'
      });
    } else {
      var errorMsg = error.response.data.message;

      if (errorMsg !== undefined) {
        Message.error({
          message: errorMsg,
          duration: 5000
        });
      }
    }
  } else {
    Message.error({
      message: '接口请求失败',
      duration: 5000
    });
  }

  return Promise.reject(error);
});
export default service;