//
//
//
//
//
//
//
//
//
export default {
  name: 'App',
  data: function data() {
    return {
      disX: 0,
      disY: 0,
      num: 0,
      timer: null,
      isClick: true,
      platform: {}
    };
  },
  destroyed: function destroyed() {
    clearInterval(this.timer);
  },
  mounted: function mounted() {
    this.getPlatform();
  },
  methods: {
    handleClickOpenAi: function handleClickOpenAi() {
      if (this.num > 0) return; // var id = location.hostname == 'open.openwhy.cn' ? '1671361675204366336' : '1679757558354677760'
      // var url = this.$router.resolve({ path: `/chat/` + id })
      // window.open(url.href, '_blank')

      var platform = JSON.parse(localStorage.getItem('platform'));
      window.open(platform.platformAiChatLink, '_blank');
    },
    // isPathShow() {//2024-3-27 改用接口的返回值判断，不再需要判断域名了
    //   if (location.hostname == 'open.openwhy.net' || location.hostname == 'open.openwhy.cn' || location.hostname == 'localhost') {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    mousedown: function mousedown(ev) {
      var _this = this;

      this.num = 0;
      var ev = ev || window.event;
      var oDiv = document.getElementById('dragImg');
      this.disX = ev.clientX - oDiv.offsetLeft;
      this.disY = ev.clientY - oDiv.offsetTop;
      this.timer = setInterval(function () {
        _this.num++;
      }, 200);

      document.onmousemove = function (ev) {
        var ev = ev || window.event;
        var L = ev.clientX - _this.disX;
        var T = ev.clientY - _this.disY;

        if (L < 0) {
          L = 0;
        } else if (L > document.documentElement.clientWidth - oDiv.offsetWidth) {
          L = document.documentElement.clientWidth - oDiv.offsetWidth;
        }

        if (T < 0) {
          T = 0;
        } else if (T > document.documentElement.clientHeight - oDiv.offsetHeight) {
          T = document.documentElement.clientHeight - oDiv.offsetHeight;
        }

        oDiv.style.left = L + 'px';
        oDiv.style.top = T + 'px';
      };

      document.onmouseup = function (e) {
        clearInterval(_this.timer);
        document.onmousemove = null;
        document.onmouseup = null;
      };

      return false;
    },

    /**
     * 获取平台信息
     */
    getPlatform: function getPlatform() {
      var platform = JSON.parse(localStorage.getItem('platform'));
      this.platform = platform;
    }
  }
};