var date = new Date();
var year = date.getFullYear();
module.exports = {
  /**
   * @description 网站标题
   */
  title: '加载中...',

  /**
   * @description 是否显示 tagsView
   */
  tagsView: true,

  /**
   * @description 固定头部
   */
  fixedHeader: true,

  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,

  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天
   */
  passCookieExpires: 1,

  /**
   * @description 是否只保持一个子菜单的展开
   */
  uniqueOpened: true,

  /**
   * @description token key
   */
  TokenKey: 'X-Authorization',

  /**
   * @description 请求超时时间，毫秒（默认5分钟）
   */
  timeout: 3000000,

  /**
   * @description 是否显示logo
   */
  sidebarLogo: true,

  /**
   * 是否显示设置的底部信息
   */
  showFooter: true,

  /**
   * 底部文字，支持html语法
   */
  footerTxt: "<a href=\"https://www.openwhy.cn/\" target=\"_blank\">\xA9".concat(year, "\u5F00\u95EE \u667A\u4E3A\u5929\u4E0B(\u5317\u4EAC)\u79D1\u6280\u6709\u9650\u516C\u53F8</a>"),

  /**
   * 备案号
   */
  caseNumber: '京ICP备17015090号-3',

  /**
   * 微信官方页面
   */
  WeChatWeb: 'https://pay.weixin.qq.com/index.php/core/home/login?return_url=%2F',

  /**
   * 小程序绑定微信支付测试说明
   */
  fileExplain: 'https://www.openwhy.cn/article/detail/1301720235627241472',

  /**
   * 开问网用户协议
   */
  agreement: '<a href="' + process.env.VUE_APP_BASE_API + '/user/agreement' + '" target="_blank">《用户协议》</a>',

  /**
   * 开问小程序用户协议
   */
  miniAgreement: '<a href="https://www.openwhy.cn/projects/892780669644513280?mod=agreement" target="_blank">《开问小程序用户协议》</a>',

  /**
   * 开问网隐私政策
   */
  policy: '<a href="' + process.env.VUE_APP_BASE_API + '/privacy/agreement' + '" target="_blank">《隐私政策》</a>'
};