import Terrace from '@/layout/terrace';
export default [{
  path: '/terrace',
  name: 'Terrace',
  component: Terrace,
  meta: {
    noCache: true
  },
  redirect: '/volunteer',
  children: [{
    path: '/volunteer',
    name: 'volunteer',
    component: function component() {
      return import(
      /* webpackChunkName: "volunteer-postulant" */
      '@/views/volunteer/postulant.vue');
    },
    meta: {
      windowStyle: ".router-view-content{background:#f5f6f7;height: 100%;}",
      title: "志愿者",
      isNoShowLogo: true,
      role: 1
    }
  }, {
    path: '/tissueList',
    name: 'tissueList',
    component: function component() {
      return import(
      /* webpackChunkName: "volunteer-tissueList" */
      '@/views/volunteer/tissueList.vue');
    },
    meta: {
      windowStyle: ".router-view-content{background:#f5f6f7;height: 100%;}",
      title: "志愿组织",
      isNoShowLogo: true,
      role: 1
    }
  }, {
    path: '/introduce',
    name: 'introduce',
    component: function component() {
      return import(
      /* webpackChunkName: "volunteer-introduce" */
      '@/views/volunteer/introduce.vue');
    },
    meta: {
      windowStyle: ".router-view-content{background:#f5f6f7;height: 100%;}",
      title: "功能设置",
      isNoShowLogo: true,
      role: 1
    }
  }, {
    path: '/active',
    name: 'volunter-active',
    component: function component() {
      return import(
      /* webpackChunkName: "volunter-active" */
      '@/views/volunteer/active.vue');
    },
    meta: {
      windowStyle: ".router-view-content{background:#f5f6f7;height: 100%;}",
      title: "志愿活动",
      isNoShowLogo: true,
      role: 2
    }
  }]
}];