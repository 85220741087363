import request from '@/utils/request'; // 获取专栏ID

export function getSpecialId(appType) {
  return request({
    url: "/p1/api/v1/wx/third-party/home-classid?appType=".concat(appType),
    method: "get"
  });
} // 查询专栏列表（不含首页）

export var getList = function getList() {
  var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var data = arguments.length > 1 ? arguments[1] : undefined;
  return request({
    url: "/p1/api/v3/wx/third-party/weapp/first-class/list?page=".concat(page),
    method: "post",
    data: data
  });
}; // 查询专栏列表

export var getListV1 = function getListV1() {
  var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var data = arguments.length > 1 ? arguments[1] : undefined;
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/first-class/list?page=".concat(page),
    method: "get"
  });
}; // 删除专栏

export function deleteSpecial(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class?id=".concat(id),
    method: "DELETE"
  });
} // 添加/编辑专栏

export function saveSpecial(id, data) {
  return request({
    url: "/p1/api/".concat(id ? 'v2' : 'v1', "/wx/third-party/weapp/class"),
    method: id ? "PUT" : "POST",
    data: data
  });
} // 根据专栏id（含首页），查询专栏下的导航列表

export function getSpecialNav(appId, parentId) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/list?appid=".concat(appId, "&parentId=").concat(parentId),
    method: "GET"
  });
}
export function getAllData(firstClassId) {
  return request({
    url: "/p2/api/v1/wx/weapp/class/".concat(firstClassId, "/all-data"),
    method: "GET"
  });
} // 删除导航

export function deleteSpecialNav(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class?id=".concat(id),
    method: "DELETE"
  });
} // 在专栏（含首页）下添加/编辑导航

export function setSpecialNav(data, type) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class",
    method: type == 1 ? "PUT" : "POST",
    data: data
  });
} // 小程序导航显示/隐藏

export function setSpecialNavShow(data) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/class/isShow',
    method: "PUT",
    data: data
  });
} // 小程序设计页，保存专题下的全部数据时，判断是否有私密文章

export function isSecretFn(firstClassId, classList) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/class/all-data/secreted-article-count',
    method: "POST",
    data: {
      firstClassId: firstClassId,
      classList: classList
    }
  });
} // 10.根据专栏id，保存该专栏下的全部导航下的全部内容

export function saveAllData(firstClassId, classList) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/class/all-data',
    method: "POST",
    data: {
      firstClassId: firstClassId,
      classList: classList
    }
  });
} // 查询专栏导航列表和专栏导航样式

export function checkspecialDetail(homeId) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class-nav/list?homeId=".concat(homeId),
    method: "GET"
  });
} // 21.保存专栏导航列表和专栏导航样式

export function saveSpecialStyle(data) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/class-nav',
    method: "POST",
    data: data
  });
}
export function saveNavLayout(data) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/class/nav-layout-type',
    method: "PUT",
    data: data
  });
} // 保存图文类型

export function saveImgText(type, data) {
  var method = type == 1 ? 'POST' : 'PUT';
  return request({
    url: '/p1/api/v1/weapp/data-slice/image-text',
    method: method,
    data: data
  });
}
/******公告**************** */

export var saveNoticeSet = function saveNoticeSet(type, data) {
  var method = type == 1 ? 'POST' : 'PUT';
  return request({
    url: '/p1/api/v1/weapp/data-slice/notice',
    method: method,
    data: data
  });
};
/*****查询管理员******************* */

export var getSpecialMant = function getSpecialMant(data) {
  return request({
    url: '/p1/api/v1/weapp/manager/class-manager/search',
    method: "POST",
    data: data
  });
}; // 保存设置的管理员

export var saveSpecialMant = function saveSpecialMant(data) {
  return request({
    url: '/p1/api/v1/weapp/manager/set-class-manager',
    method: "POST",
    data: data
  });
};
/**
 * 查询专栏下的轮播图列表和尺寸类型
 */

export var getSpecialBanner = function getSpecialBanner(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/class/banner/list?id=".concat(id),
    method: "GET"
  });
};
/**
 * 保存专栏下的轮播图和尺寸类型
 */

export var saveSpecialBanner = function saveSpecialBanner(data) {
  return request({
    url: '/p1/api/v1/wx/third-party/weapp/class/banner',
    method: "POST",
    data: data
  });
};
/**
 * 查询专栏模板库列表
 */

export var getTemplateList = function getTemplateList(data) {
  return request({
    url: "/p1/api/v1/weapp/class/template-standard/list",
    method: "GET"
  });
};
/**
 * 查询模板类型列表
 * type：模板类型，1：首页模板，2：专栏模板，非必填，不传则查询首页模板
 */

export var getWeappTemplateType = function getWeappTemplateType(type) {
  var url = "/p1/api/v1/wx/third-party/weapp/template-standard-class/list";

  if (type) {
    url += "?templateType=".concat(type);
  }

  return request({
    url: url,
    method: "GET"
  });
};
/**
 * 查询模板列表
 */

export var getWeappTemplateList = function getWeappTemplateList(id) {
  return request({
    url: "/p1/api/v1/wx/third-party/weapp/template-standard/list?classId=".concat(id),
    method: "GET"
  });
};
/**
 * 复制模板列表
 */

export var copySpecial = function copySpecial(id) {
  return request({
    url: "/p1/api/v1/weapp/class/copy",
    method: "POST",
    data: {
      id: id
    }
  });
};
/**
 * 获取随机标题图
 * @returns 
 */

export function getRandomImgUrl() {
  return request({
    url: '/p2/api/v1/activities/type-images/random-one',
    method: "GET"
  });
}