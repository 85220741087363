/**
 * 转为html
 * @param {Object} text
 */
export default function htmlUnEscape(text) {
  var temp = document.createElement("div");
  temp.innerHTML = text;
  var output = temp.innerText || temp.textContent;
  temp = null;
  return output;
}
/**
 * html转码
 * @param {Object} html
 */

export function htmlEscape(html) {
  var temp = document.createElement("div");
  temp.textContent != null ? temp.textContent = html : temp.innerText = html;
  var output = temp.innerHTML;
  temp = null;
  return output;
}
export function convertToPlain(html) {
  //新创建一个 div
  var divElement = document.createElement("div"); //设置HTML给它

  divElement.innerHTML = html; //获取文本内容

  return divElement.textContent || divElement.innerText || "";
}