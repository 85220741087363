import request from '@/utils/request'; //密码登录

export function loginPassword(data) {
  return request({
    url: '/p2/api/v2/auth/login',
    method: 'post',
    data: data
  });
} //验证码登录

export function loginPhoneCode(data) {
  return request({
    url: '/p2/api/v1/fast-login',
    method: 'post',
    data: data
  });
}
export function getInfo() {
  return request({
    url: '/p2/api/v1/persons/information/basic',
    method: 'get'
  });
}
export function getAccounts() {
  return request({
    url: '/p2/api/v1/persons/accounts',
    method: 'get'
  });
}
/**
 * 获取创建文章素材权限
 */

export function getHomeEdu() {
  return request({
    url: '/p1/api/v1/home-edu/user/type-list',
    method: 'get'
  });
} // export function getCodeImg() {
//   return request({
//     url: '/p1/api/auth/code',
//     method: 'get'
//   })
// }
//快速登录发验证码

export function getPhoneCode(data) {
  return request({
    url: '/p2/api/v1/sms/captcha/fastlogin',
    method: 'post',
    data: data
  });
} //注册验证码

export function getPhoneCode2(data) {
  return request({
    url: '/p2/api/v1/sms/captcha/register',
    method: 'post',
    data: data
  });
} //修改密码验证码

export function getPhoneCode3(data) {
  return request({
    url: '/p2/api/v1/sms/captcha/resetpassword',
    method: 'post',
    data: data
  });
} //查看昵称是否可用

export function checkName(nickname) {
  return request({
    url: '/p2/api/v1/register/nickname-available',
    method: 'post',
    data: {
      nickname: nickname
    }
  });
} //注册

export function register(data) {
  return request({
    url: '/p2/api/v1/register/users',
    method: 'post',
    data: data
  });
} //修改密码

export function changePasswords(data) {
  return request({
    url: '/p2/api/v2/reset-password/users',
    method: 'put',
    data: data
  });
}
export function logout() {
  return request({
    url: '/p1/api/auth/logout',
    method: 'delete'
  });
} //登录、注册、修改密码是否需要极验

export function isNeedGeetest() {
  return request({
    url: '/p2/api/v1/login/need-geetest',
    method: 'get'
  });
}
/**
 * 判断是否绑定手机
 */

export function isRealName() {
  return request({
    url: '/p2/api/v1/persons/isRealnameAuth',
    method: 'get'
  });
}
/**
 * 绑定手机发送验证码
 */

export function sendBindCode(data) {
  return request({
    url: '/p2/api/v1/sms/captcha/realnameauth',
    method: 'post',
    data: data
  });
}
/**
 * 判断绑定手机类型
 */

export function bindTelType(mobile, captcha) {
  return request({
    url: "/p2/api/v2/persons/bind-mobile/validate?phone=".concat(mobile, "&captcha=").concat(captcha),
    method: 'get'
  });
}
/**
 * 绑定新手机
 */

export function bindNewTel(data) {
  return request({
    url: "/p2/api/v3/persons/mobile",
    method: 'put',
    data: data
  });
}
export function getWxCode() {
  return request({
    url: "/p2/api/v1/mp/login/subscribe-url",
    method: "GET"
  });
}
export function getWxCodeCheck(sceneId) {
  return request({
    url: "/p2/api/v1/mp/login/status?sceneId=".concat(sceneId),
    method: "GET"
  });
}
/**
 * 合并账号
 */
// export function mergeAccount(data) {
//   return request({
//     url: `/p2/api/v3/persons/mobile`,
//     method: 'put',
//     data
//   })
// }

/**
 * 解绑手机
 */
// export function unBindTel(data) {
//   return request({
//     url: `/p2/api/v3/persons/mobile`,
//     method: 'put',
//     data
//   })
// }
// 绑定手机号

export function bindMobile(data) {
  return request({
    url: "/p2/api/v1/persons/mobile-change/send-email?mobile=".concat(data.mobile, "&captcha=").concat(data.captcha),
    method: 'get'
  });
} // 验证密码

export function verifyPass(data) {
  return request({
    url: "/p2/api/v1/persons/bind-email/judge-password",
    method: 'post',
    data: data
  });
} // 获取邮箱

export function getEmail(data) {
  return request({
    url: "/p2/api/v1/persons/bind-email/judge-email?email=".concat(data.email),
    method: 'get'
  });
} // 发送邮箱

export function sendEmail(data) {
  return request({
    url: "/p2/api/v1/persons/bind-email/send-email",
    method: 'POST',
    data: data
  });
} // 确认修改密码

export function queryPassW(data) {
  return request({
    url: "/p2/api/v1/persons/information/password",
    method: 'put',
    data: data
  });
} // 解绑微信

export function unBindWeiXin(type) {
  return request({
    url: "/p2/api/v1/persons/unbind?type=".concat(type == 'weixin' ? 1 : 4),
    method: 'delete'
  });
} // 修改基本信息

export function updateBaseInfo(data) {
  return request({
    url: '/p2/api/v1/persons/information/basic',
    method: "PUT",
    data: data
  });
} // 修改头像

export function updateBaseAvatr(data) {
  return request({
    url: '/p2/api/v1/persons/information/avatars',
    method: "PUT",
    data: data
  });
} // 更换手机号时获取验证码

export function updateChangePhone(data) {
  return request({
    url: '/p2/api/v1/sms/captcha/changephone',
    method: "post",
    data: data
  });
} // 获取平台信息

export function platformSet() {
  return request({
    url: '/p1/api/v1/platform-setting',
    method: "GET",
    hideToastAll: true
  });
} // 获取第三方平台信息

export function platformThridSet(key) {
  return request({
    url: '/p1/api/v1/virtual-platform/setting?key=' + key,
    method: "GET",
    hideToastAll: true
  });
} // 获取验证方式

export function getVerifyType() {
  return request({
    url: '/p2/api/v2/captcha-code',
    method: "GET"
  });
} // 更换图文算式验证码

export function cutVerifyImg() {
  return request({
    url: '/p2/api/v1/captcha-code',
    method: "GET"
  });
}